import React, {
  useRef,
  Children,
  isValidElement,
  cloneElement,
  useState,
  PropsWithRef,
  useEffect,
} from "react";
import { AnimatePresence, MotionProps } from "framer-motion";

interface StepFlowProps {
  step: number;
  initialContainerHeight?: number;
}

const StepFlow: React.FC<StepFlowProps> = ({
  step,
  children,
  initialContainerHeight,
}) => {
  const [containerHeight, setContainerHeight] = useState(
    initialContainerHeight || 0,
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          const target = entry.target as HTMLDivElement;
          setContainerHeight(target.offsetHeight);
        }
      },
    );

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [step]);

  return (
    <div style={{ position: "relative", height: containerHeight }}>
      <AnimatePresence initial={false}>
        {Children.map(children, (child) => {
          if (
            isValidElement<MotionProps>(child) &&
            Number(child.key) === step
          ) {
            const variants = {
              enter: {
                x: "100%",
                opacity: 0,
              },
              center: { zIndex: 1, x: 0, opacity: 1 },
              exit: {
                zIndex: 0,
                x: "-100%",
                opacity: 0,
              },
            };

            return cloneElement(child, {
              ref,
              variants,
              initial: "enter",
              animate: "center",
              exit: "exit",
              transition: { type: "spring", stiffness: 300, damping: 30 },
              style: { position: "absolute", width: "100%" },
            }) as PropsWithRef<MotionProps>;
          }
          return null;
        })}
      </AnimatePresence>
    </div>
  );
};

export default StepFlow;
