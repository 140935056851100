import { z } from "zod";
import { isCypress } from "../api.utils";
import { setCookie } from ".";

const cookieDomain = isCypress
  ? "localhost"
  : process.env.NEXT_PUBLIC_PUSHPRESS_COOKIE_WEBSITE;

export const accessTokenSchema = z.object({
  clientUuid: z.string(),
  originClientUuid: z.string().nullish(),
  role: z.string(),
});

export const CONTROL_PANEL_LOGIN_COOKIE_NAME = "ACCESS_TOKEN";
export const CONTROL_PANEL_REFRESH_COOKIE_NAME = "REFRESH_TOKEN";

/**
 * Set the access token and refresh token cookies
 * for now refresh token is optional but in the near future will always be set with an access token
 *
 */
export const setTokenCookies = (
  accessToken: string,
  refreshToken?: string | null,
) => {
  if (refreshToken) {
    setCookie(CONTROL_PANEL_REFRESH_COOKIE_NAME, refreshToken, {
      domain: cookieDomain,
      sameSite: "Lax",
    });
  }
  setCookie(CONTROL_PANEL_LOGIN_COOKIE_NAME, accessToken, {
    domain: cookieDomain,
    sameSite: "Lax",
  });
};
